var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-7"},[_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.form === 1),expression:"form === 1"}],ref:"firstForm",staticClass:"geko-form-wrapper",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('geko-input',{attrs:{"item":{
                label: 'Tahun Program',
                validation: ['required'],
                col_size: 6,
                type: 'select',
                setter: 'program_year',
                option: {
                list_pointer: {
                    label: 'label',
                    code: 'code',
                    display: ['label'],
                },
                default_options: [
                    {
                    label: '2020',
                    code: 2020,
                    },
                    {
                    label: '2021',
                    code: 2021,
                    },
                    {
                    label: '2022',
                    code: 2022,
                    },
                    {
                    label: '2023',
                    code: 2023,
                    },
                    {
                    label: '2024',
                    code: 2024,
                    },
                    {
                    label: '2025',
                    code: 2025,
                    }
                ],
                },
            }},model:{value:(_vm.formData.program_year),callback:function ($$v) {_vm.$set(_vm.formData, "program_year", $$v)},expression:"formData.program_year"}})],1),_c('v-col',[_c('geko-input',{attrs:{"disabled":!_vm.formData.program_year,"item":{
                label: 'Regional',
                validation: ['required'],
                col_size: 6,
                type: 'select',
                setter: 'region',
                param: {
                page: 1,
                per_page: 10,
                },
                api: 'new-utilities/region/list',
                default_label: _vm.formData.region,
                option: {
                getterKey: 'result',
                list_pointer: {
                    code: 'region_code',
                    label: 'name',
                    display: ['name', 'region_code'],
                },
                },
            }},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}})],1)],1),_c('v-row',[_c('v-col',[_c('geko-input',{attrs:{"item":{
                label: 'Posisi',
                validation: ['required'],
                col_size: 6,
                type: 'select',
                setter: 'employee_position',
                option: {
                list_pointer: {
                    label: 'label',
                    code: 'code',
                    display: ['label'],
                },
                default_options: [
                    {
                    label: 'Regional Manager',
                    code: 23,
                    },
                    {
                    label: 'Unit Manager',
                    code: 20,
                    },
                    {
                    label: 'Field Coordinator',
                    code: 19,
                    },
                    {
                    label: 'Field Facilitator',
                    code: 'ff',
                    }
                ],
                },
            }},model:{value:(_vm.formData.employee_position),callback:function ($$v) {_vm.$set(_vm.formData, "employee_position", $$v)},expression:"formData.employee_position"}})],1),_c('v-col',[(_vm.formData.employee_position == 'ff' || _vm.formData.employee_position == 19 || _vm.formData.employee_position == 20)?_c('geko-input',{attrs:{"disabled":!_vm.formData.program_year || !_vm.formData.region,"item":{
                label: 'Management Unit',
                validation: ['required'],
                col_size: 6,
                type: 'select',
                setter: 'mu_no',
                param: {
                    page: 1,
                    per_page: 10,
                },
                api: 'new-utilities/management-units',
                default_label: _vm.formData.mu_no,
                option: {
                getterKey: 'data',
                list_pointer: {
                    code: 'mu_no',
                    label: 'name',
                    display: ['name', 'mu_no'],
                },
                },
            }},model:{value:(_vm.formData.mu_no),callback:function ($$v) {_vm.$set(_vm.formData, "mu_no", $$v)},expression:"formData.mu_no"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('geko-input',{attrs:{"item":{
                    label: 'Assign Kepada: ',
                    validation: ['required'],
                    col_size: 6,
                    type: 'select',
                    api: 'getEmployeeList_new',
                    setter: 'user_id',
                    default_label: _vm.formData.user_id,
                    option: {
                    getterKey: 'data',
                    list_pointer: {
                            code: 'nik',
                            label: 'name',
                            display: ['name', 'nik'],
                        },
                    },
                }},model:{value:(_vm.formData.user_id),callback:function ($$v) {_vm.$set(_vm.formData, "user_id", $$v)},expression:"formData.user_id"}})],1),_c('v-col',[(_vm.formData.employee_position == 'ff' || _vm.formData.employee_position == 19)?_c('geko-input',{attrs:{"disabled":!_vm.formData.program_year || !_vm.formData.mu_no,"item":{
                    label: 'Target Area',
                    validation: ['required'],
                    col_size: 6,
                    type: 'select',
                    setter: 'target_area',
                    param: {
                    page: 1,
                    per_page: 10,
                    program_year: _vm.formData.program_year,
                    mu_no: _vm.formData.mu_no
                    },
                    api: 'new-utilities/target-areas',
                    default_label: _vm.formData.target_area,
                    option: {
                    getterKey: 'data',
                    list_pointer: {
                        code: 'area_code',
                        label: 'name',
                        display: ['name', 'area_code'],
                        status: 1,
                        user_id: null,
    
                    },
                    },
                }},model:{value:(_vm.formData.target_area),callback:function ($$v) {_vm.$set(_vm.formData, "target_area", $$v)},expression:"formData.target_area"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col'),_c('v-col',[(_vm.formData.employee_position == 'ff')?_c('geko-input',{attrs:{"disabled":!_vm.formData.program_year || !_vm.formData.mu_no || !_vm.formData.target_area,"item":{
                    label: 'Desa',
                    validation: ['required'],
                    col_size: 6,
                    type: 'select',
                    api: 'GetDesaByKecamatanTA_new',
                    param: {
                        ta_no: _vm.formData.target_area,
                        program_year: _vm.formData.program_year
                    },
                    setter: 'village',
                    option: {
                        getterKey: 'data',
                        list_pointer: {
                        label: 'name',
                        code: 'kode_desa',
                        display: ['name', 'kode_desa'],
                            },
                        },
                    }},model:{value:(_vm.formData.village),callback:function ($$v) {_vm.$set(_vm.formData, "village", $$v)},expression:"formData.village"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-content-end",staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{attrs:{"type":"submit","variant":"success","disabled":_vm.loading}},[_c('v-icon',[_vm._v("mdi-check-bold")]),_c('span',[_vm._v("Submit")])],1)],1)])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }